import React from 'react'
import graph1 from "../assets/graph1.png"
import graph2 from "../assets/graph2.png"
import graph3 from "../assets/graph3.png"
import graph4 from "../assets/graph4.png"
import graph5 from "../assets/graph5.png"
import graph6 from "../assets/graph6.png"
import graph7 from "../assets/graph7.png"
import graph8 from "../assets/graph8.png"
import graph9 from "../assets/graph9.png"

const graphsData = [
  {
    id: 1,
    imageSrc: graph1,
    title: "Sales chart related to different companies and market genres.",
    link: "https://public.tableau.com/app/profile/kaio.viana/viz/projectgamers/Dashboard1",
  },
  {
    id: 2,
    imageSrc: graph2,
    title: "Graph comparing temperature variations between the 20 most affected regions.",
    link: "https://public.tableau.com/app/profile/kaio.viana/viz/dashboardfirstcountries/Dashboard1",
  },
  {
    id: 3,
    imageSrc: graph3,
    title: "Average number of passengers per month in relation to macro regions and the largest commercial aviation companies.",
    link: "https://public.tableau.com/app/profile/kaio.viana/viz/MdiaPassageirosporRegio2/Sheet2",
  },
  {
    id: 4,
    imageSrc: graph4,
    title: "Dashboard of average temperature variation in macro-regions over the last 20 years compared to global temperature.",
    link: "https://public.tableau.com/app/profile/kaio.viana/viz/continentstempprime/Dashboard1",
  },
  {
    id: 5,
    imageSrc: graph5,
    title: "Variation in global temperature over the last 60 years and forecast for the next 20 years using Machine Learning.",
    link: "https://public.tableau.com/app/profile/kaio.viana/viz/prev_temp_future/Sheet1",
  },
  {
    id: 6,
    imageSrc: graph6,
    title: "Performance analysis dashboard of study, using genetic algorithm NEAT, in game with different difficulties.",
    link: "https://public.tableau.com/app/profile/kaio.viana/viz/NeuralGamestudies/Dashboard1",
  },
  {
    id: 7,
    imageSrc: graph7,
    title: "Analysis of climate change over the last 60 years depending on the seasons.",
    link: "https://public.tableau.com/app/profile/kaio.viana/viz/sessionstemp/Sheet1",
  },
  {
    id: 8,
    imageSrc: graph8,
    title: "Study dashboard on the share values ​​of the main companies since 2017.",
    link: "https://public.tableau.com/app/profile/kaio.viana/viz/FinanceStudies15/Dashboard1",
  },
  {
    id: 9,
    imageSrc: graph9,
    title: "Correlation matrix between the most relevant commercial aviation companies in the current scenario.",
    link: "https://public.tableau.com/app/profile/kaio.viana/viz/MatrizdeCorrelao_16951464129580/Sheet1#1",
  },
];

export default function Gallery() {
  return (
    <div id='about' className='pt-28'>
      <h1 className=' text-slate-600 text-center  font-bold text-4xl mx-auto '>Gallery</h1>
      <div id='education' className='container grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-2 items-center justify-center mt-14 m-auto ' >

        {graphsData.map((graph) => (
          <a 
            className='rounded-xl shadow-lg cursor-pointer overflow-hidden w-96 h-36 sm:w-96a sm:h-48 tooltip m-auto' 
            href={graph.link} 
            target="_blank"
          >
            <img 
              className='hover:scale-105 duration-300'
              src={graph.imageSrc} 
              alt="graphic"
            /> 
            <div class="tooltip-text">{graph.title} </div>
          </a>
        ))}

      </div>
    </div>
  )
}

