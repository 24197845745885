import React from 'react'

import { FcSms, FcServices, FcBullish } from "react-icons/fc";


export default function Steps() {
  return (
    <div id='education' className='container flex flex-col items-center justify-center mt-14 m-auto ' >
      <div className='grid grid-cols-1 md:grid-cols-3 gap-4 lg:gap-20 mt-20 p-2'>

        <div className='container flex flex-col justify-center items-center '>
            <div className='shadow-md bg-gray-100 flex flex-col justify-center items-center rounded-full w-24 h-24 mb-5 hover:scale-95 transition duration-300 ease-in-out'>
                <FcSms size={40}/>  
            </div>
            <div className='shadow-md w-full md:w-80 h-64 bg-slate-100 mb-3 rounded-3xl '>
                <div className='flex flex-col items-center justify-center m-auto text-slate-600 p-3'>
                    <h1 className='text-xl md:text-lg mb-4'>Background</h1>
                    <span className='text-sm text-slate-400 text-center'>Coming from a background in <span className='text-gray-700'>full-stack</span> development and UI design, I possess both theoretical and practical knowledge in layout development. I have extensive experience in data management using <span className='text-gray-700'> Python </span> and <span className='text-gray-700'>SQL</span> 
                    , and I have had the opportunity to work with other technologies such as <span className='text-gray-700'>TypeScript</span>, <span className='text-gray-700'>Node.js</span>, and <span className='text-gray-700'>NoSQL</span> databases like Cassandra, among others.</span>
                </div>
            </div>
        </div>

        <div className='container flex flex-col justify-center items-center'>
            <div className='shadow-md bg-gray-100 flex flex-col justify-center items-center rounded-full w-24 h-24 mb-5 hover:scale-95 transition duration-300 ease-in-out'>
                <FcServices size={40}/>  
            </div>
            <div className='shadow-md w-full md:w-80 h-64 bg-slate-100 mb-3 rounded-3xl '>
                <div className='flex flex-col items-center justify-center m-auto text-slate-600 p-3'>
                    <h1 className='text-xl md:text-lg mb-4'>Machine Learning</h1>
                    <span className='text-sm text-slate-400 text-center'>I have already had the opportunity to develop <span className='text-gray-700'>projects</span> in an academic and personal environment using different supervised and unsupervised <span className='text-gray-700'>Machine Learning</span> techniques and algorithms, as well as <span className='text-gray-700'>K-Means</span>, <span className='text-gray-700'>Hierarchical</span> and several other clustering processes, As well as <span className='text-gray-700'>linear regression</span> processes, correction matrices and neural networks algorithms like <span className='text-gray-700'>NEAT</span>. </span>
                </div>
            </div>
        </div>

        <div className='container flex flex-col justify-center items-center'>
            <div className='shadow-md bg-gray-100 flex flex-col justify-center items-center rounded-full w-24 h-24 mb-5 hover:scale-95 transition duration-300 ease-in-out'>
                <FcBullish size={40}/>  
            </div>
            <div className='shadow-md w-full md:w-80 h-64 bg-slate-100 mb-3 rounded-3xl '>
                <div className='flex flex-col items-center justify-center m-auto text-slate-600 p-3'>
                    <h1 className='text-xl md:text-lg mb-4'>Visualise</h1>
                    <span className='text-sm text-slate-400 text-center'>I have a strong background in <span className='text-gray-700'>graphic design</span> with experience in crafting visually appealing and <span className='text-gray-700'>effective</span> designs. In addition to my graphic design expertise, I have honed my data visualization skills through proficiency in tools such as <span className='text-gray-700'>Tableau</span> and <span className='text-gray-700'>Power BI</span>. These tools have allowed me to translate complex data sets into meaningful <span className='text-gray-700'>insights</span> and engaging visual presentations.</span>
                </div>
            </div>
        </div>

      </div>
        

    </div>
  )
}

