import React from 'react'
import foto from "../assets/sitecoin.jpg"
import foto2 from "../assets/post2.jpg"
import foto3 from "../assets/post3.jpg"
import foto4 from "../assets/sitecarstar.jpg"
import ProjectCardRight from './projectCardRight';
import ProjectCardLeft from './projectCardLeft';




export default function Projects() {
  return (
    <div id='projects' className='container  grid grid-cols-1 gap-10 items-center justify-center mt-20 m-auto'>
    <h1 className='mt-14 text-slate-600 text-center  font-bold text-4xl mx-auto '>Projects</h1>
      <ProjectCardRight
        link="https://medium.com/@kaiod.oficial/climate-change-studies-1fd5c00fbe64"
        image={foto}
        title='Climate Change Studies'
        content='Project carried out on climate change over the last 60 years, data provided by NASA. The data was processed, analyzed and used in an estimate using machine learning.'
        tech='Python • SQL • Linear Regression • Tableau • PySpark • Pandas'

      />
      <ProjectCardLeft
        link="https://medium.com/@kaiod.oficial/tesla-stocks-clustering-stories-3ae9a74c4fe0"
        image={foto2}
        title='TESLA STOCKS Clustering Stories'
        content="This project delves into the intricate patterns of Tesla's stock values over the past eleven years, employing a variety of clustering and machine learning techniques. Through meticulous analysis, we aim to unravel the hidden stories within this data, shedding light on the Tesla's stock performance journey."
        tech='Python • Machine Learning • matplotlib • PySpark • sklearn'
  

      />
       <ProjectCardRight
          link="https://medium.com/@kaiod.oficial/how-do-airlines-connect-86d586a5c0a"
          image={foto3}
          title='How do airlines connect?'
          content='A project that analyzes how airlines connect, classifying them into groups according to their correlations, creating a correlation matrix, after which clustering is carried out with K-Means and the results are discussed.'
          tech='Python • Machine Learning • K-Means • PySpark • sklearn • Pandas • Numpy • matplotlib'
       
      />
      {/* <ProjectCardLeft
        link="https://kaiodeodato.github.io/carstar"
        image={foto4}
        title='Car Star'
        content='CarStar is a portfolio website that presents a car rental service designed to provide a seamless and hassle-free experience. With a clean and modern interface, CarStar offers visitors a user-friendly platform to explore an extensive fleet of cars, make reservations, and access all the necessary information they need to rent a car.'
        tech='React.js • HTML5 • Javascript • Tailwind • CSS3 • JSON • Router Dom • RestApi'
        
      /> */}
      
    </div>
  )
}
